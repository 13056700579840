import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GetUnitList = gql`
  ${STATUS_FIELDS}
  query getUnitList($UnitListInput: UnitListInput!) {
    getUnitList(input: $UnitListInput) {
      status {
        ...StatusFields
      }
      Timeline {
        id
        name
        title
        enabled
        releaseDate
        progress
        total
        completed
        presential
        status {
          id
          name
          detail
          enabled
          style
        }
      }
      Units {
        id
        finished
        name
        title
        detail
        releaseDate
        progress
        href
        enabled
        isMandatory
        isExpired
        thumbnail
        description
        introduction {
          status
          style
        }
        label {
          id
          name
          enabled
          detail
          style
          date
        }
        lessons {
          id
          name
          finished
          introduction {
            status
            style
          }
          href
          type
          Parent {
            id
            name
          }
          label {
            id
            name
            enabled
            detail
            style
          }
          status {
            id
            name
            enabled
            detail
            style
          }
          indicators {
            name
            image
            max
            obtained
            startDate
            endDate
          }
        }
        status {
          id
          name
          detail
          style
        }
        Goals {
          title
          description
        }
        transversal
        qualification {
          value
          valueFormatted
          status {
            id
            name
            detail
            style
          }
        }
        indicators {
          name
          image
          startDate
          endDate
        }
        items {
          id
          name
          finished
          introduction {
            status
            style
          }
          thumbnail
          completed
          releaseDate
          enabled
          href
          Topics {
            colour
            initials
            title
            description
            LearningGoals {
              title
              description
            }
          }
          indicators {
            name
            image
            max
            obtained
            startDate
            endDate
          }
          qualification {
            value
            valueFormatted
            status {
              id
              name
              style
            }
          }
          label {
            id
            name
            enabled
            detail
            style
            date
          }
          Requirements {
            id
            resourceId
            resourceRequiredId
            Requirement {
              id
              title
            }
          }
          status {
            id
            name
            enabled
            detail
            style
          }
          downloadPdf
          type
          PreviewVideo {
            title
            Video {
              type
              path
            }
            summary
            link
            config {
              link
              studentId
              courseId
              unitId
              resourceId
              reasonsLink
            }
          }
          isMandatory
          isExpired
        }
        downloadPdf
        type
      }
      pending {
        id
        name
        unitId
      }
    }
  }
`

export type GetUnitListPayload = Api.UnitListPayload

export type GetUnitListPayInput = Api.UnitListInput

interface GetUnitListPayloadApollo {
  getUnitList: GetUnitListPayload
}

interface GetUnitListInputApollo {
  UnitListInput: GetUnitListPayInput
}

export const useLazyGetUnitList = (
  options?: LazyQueryHookOptions<GetUnitListPayloadApollo, GetUnitListInputApollo>
) => {
  return useLazyQuery<GetUnitListPayloadApollo, GetUnitListInputApollo>(GetUnitList, options)
}
