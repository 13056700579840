import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GetCourseInfo = gql`
  ${STATUS_FIELDS}
  query getCourseInfo($CourseInfoInput: CourseInfoInput!) {
    getCourseInfo(input: $CourseInfoInput) {
      status {
        ...StatusFields
      }
      Terms {
        id
        text
      }
      Course {
        id
        providerVr
        principalProviderVr
        studentId
        campusId
        legalText
        name
        progress
        href
        revisedWelcome
        haveQuestions
        navigationId
        remaining
        programId
        deactivationDate
        i18nTag
        attendancePercentage
        hasProctoring
        showAverages
        activeUnit {
          id
          title
        }
        Product {
          name
          brandingLogo
        }
        Language {
          id
          name
        }
        CourseConfiguration {
          slots
        }
        hasScorm
      }
      Welcome {
        about
        objetives
        videos
        photo
        streamId
        Documents {
          id
          name
          href
        }
        Contacts {
          tag
          photo
          fullName
          email
          phone
          whatsapp
        }
        AcademicTeam {
          id
          Person {
            picture
            fullName
            professionalResume
          }
          Profile {
            name
          }
        }
      }
      footerBoxes {
        type
        title
        content
        photo
        fullName
        email
        phone
        whatsapp
        href
        attentionTime
        links {
          name
          href
          status {
            enabled
          }
          type {
            id
            name
          }
        }
      }
      Sence {
        urls {
          name
          href
          status {
            id
            name
            enabled
            detail
          }
        }
        RutOtec
        Token
        CodSence
        CodigoCurso
        LineaCapacitacion
        RunAlumno
        IdSesionAlumno
        needLogin
      }
      Student {
        courseId
        profileId
        warning
        provisional
        Profile {
          id
          name
        }
        dates {
          start
          end
          deactivation
          canGenerateActivity
        }
      }
      Branding {
        Pdf {
          imageCampus
          imageProduct
        }
      }
      CourseRankings {
        id
        name
      }
    }
  }
`

export type GetCourseInfoPayload = Api.CourseInfoPayload

export type GetCourseInfoInput = Api.CourseInfoInput

interface GetCourseInfoPayloadApollo {
  getCourseInfo: GetCourseInfoPayload
}

interface GetCourseInfoInputApollo {
  CourseInfoInput: GetCourseInfoInput
}

export const useLazyGetCourseInfo = (
  options?: LazyQueryHookOptions<GetCourseInfoPayloadApollo, GetCourseInfoInputApollo>
) => {
  return useLazyQuery<GetCourseInfoPayloadApollo, GetCourseInfoInputApollo>(GetCourseInfo, options)
}
