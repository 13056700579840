import { UniversalTypeUnion } from '@eclass/api'

import { formatDate } from 'app/Services'
import Types from './types'
import { setAttendance } from 'app/Views/Units/Item/AttendanceStatusAndIntro/setAttendance'

/**
 * Hook para calcular las clases presenciales y guardarlas en un objeto ordenadas por
 * - Mes -> Lessons[]
 *
 */
export const setLessons = (units: Types.UnitBlock[]): Types.ISetLessons => {
  const isPresencialClass = (type: UniversalTypeUnion) =>
    type === 'MEETING' || type === 'MEETING_REMOTE'

  const { attendanceType } = setAttendance()
  /**
   * Reduce all lessons and sort by Date.
   */
  const lessons = units
    ?.reduce<Types.BlockLessonResume[]>((acc, unit) => {
      const { type, transversal, lessons: childLessons } = unit
      /**
       * Collect transversal lessons
       */
      if (isPresencialClass(type) && transversal) {
        const calendar = findIndicatorCalendar(unit)!
        acc.push({
          id: unit.id,
          name: unit.name,
          href: unit.href,
          introduction: unit.introduction,
          status: unit.status!,
          label: unit.label!,
          indicators: unit!.indicators!,
          // El .replace /-/ por /, es para corregir los errores en Safari
          date: new Date(calendar.name.replace(/-/g, '/')),
          dateString: calendar.name,
          startDate: new Date(calendar.startDate!),
          endDate: new Date(calendar.endDate!),
          type: unit.type,
          finished: unit?.finished || false,
        })
      }

      /**
       * Collect children lessons
       */
      if (childLessons && childLessons.length > 0) {
        childLessons.forEach((lesson) => {
          const calendar = findIndicatorCalendar(lesson)!
          const partial = {
            ...lesson,
            // El .replace /-/ por /, es para corregir los errores en Safari
            date: new Date(calendar.name.replace(/-/g, '/')),
            dateString: calendar.name,
            startDate: new Date(calendar.startDate!),
            endDate: new Date(calendar.endDate!),
          }
          acc.push(partial)
        })
      }

      return acc
    }, [])
    .sort((a, b) => {
      /**
       * Ordena las clases por fecha ASC
       */
      return a.date.getTime() - b.date.getTime()
    })

  if (!lessons) {
    return {
      totalClass: 0,
    }
  }

  /** Get next lesson `Pending` that is after now. */
  const nextLesson = lessons.find(({ endDate }) => endDate >= new Date())

  const countAttendance = (items: Types.BlockLessonResume[], key) =>
    items.reduce((acc, lesson) => {
      const attendance = attendanceType({
        id: lesson.finished ? lesson.status?.id || 0 : 0,
        type: lesson.type,
        isLesson: lesson.Parent !== undefined,
      })
      return acc + (attendance[key] ? 1 : 0)
    }, 0)

  const totalAttend = countAttendance(lessons, 'attended')
  const totalAbsent = countAttendance(lessons, 'notAttend')
  const countJustify = countAttendance(lessons, 'justify')

  /**  Se modifica la lógica del porcentaje de asistencia restando del total la justificada **/
  const averageAttend = Math.round((100 / (lessons.length - countJustify)) * totalAttend)

  return {
    totalClass: lessons.length,
    totalAttend,
    totalAbsent,
    averageAttend: isNaN(averageAttend) ? 0 : averageAttend,
    justifyAbsence: countJustify,
    monthLessons: parseLessonMonth(lessons),
    nextLesson,
    lessons,
  }
}

/**
 * Busca en los Indicadores del elemento si hay un indice que sea "calendar"
 * retorna el indicador (valor que viene desde el API).
 */
const findIndicatorCalendar = ({ indicators }: Types.UnitBlock | Types.UnitBlockItemLesson) => {
  return indicators?.find(({ image }) => image === 'calendar')
}

/**
 * Agrupa las clases presenciales por mes.
 */
const parseLessonMonth = (lessons: Types.BlockLessonResume[]) => {
  const monthLessons: Map<string, Types.BlockLessonResume[]> = new Map()

  lessons.forEach((lesson) => {
    // sumamos un día más para no tener problema con el date-fns y la zona horaria.
    const month = `${formatDate(lesson.date, 'yyyy-MM')}-02`
    const mapUpdate = !monthLessons.has(month) ? [lesson] : [...monthLessons.get(month)!, lesson]

    monthLessons.set(month, mapUpdate)
  })
  return monthLessons
}

export default setLessons
