import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const EvaluationGenerate = gql`
  ${STATUS_FIELDS}
  mutation EvaluationGenerate($EvaluationGenerateInput: EvaluationGenerateInput!) {
    EvaluationGenerate(input: $EvaluationGenerateInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type EvaluationGeneratePayload = Api.EvaluationGeneratePayload

export type EvaluationGenerateInput = Api.EvaluationGenerateInput

interface EvaluationGeneratePayloadApollo {
  EvaluationGenerate: EvaluationGeneratePayload
}

interface EvaluationGenerateInputApollo {
  EvaluationGenerateInput: EvaluationGenerateInput
}

export const useMutationEvaluationGenerate = (
  options?: MutationHookOptions<EvaluationGeneratePayloadApollo, EvaluationGenerateInputApollo>
) => {
  return useMutation<EvaluationGeneratePayloadApollo, EvaluationGenerateInputApollo>(
    EvaluationGenerate,
    options
  )
}
