import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const WorkDetail = gql`
  ${STATUS_FIELDS}
  query WorkDetail($ResourceInput: ResourceInput!) {
    WorkDetail(input: $ResourceInput) {
      status {
        ...StatusFields
      }
      Alert {
        text
        type
      }
      Config {
        workId
        workTeamId
        isEvaluated
        state
        hasGrade
        hasGradeGroup
        privacy {
          showStudentCellphone
          showStudentEmail
          showWorkCellphone
          showWorkEmail
          emailReadonly
          PrimaryPhone {
            id
            number
          }
          Phones {
            id
            number
          }
        }
      }
      Header {
        title
        detail
        image
        qualification {
          value
          valueFormatted
          status {
            id
            enabled
            name
            detail
            style
          }
        }
        qualificationNote
        state
      }
      Info {
        deadline
        Teacher {
          id
          profileId
          Person {
            id
            fullName
            email
            picture
            emailPrivate
          }
        }
        Helper {
          id
          profileId
          Person {
            id
            fullName
            email
            picture
            emailPrivate
          }
        }
      }
      Group {
        id
        meetByCall
        callUrl
        Team {
          id
          personId
          Person {
            id
            fullName
            email
            emailPrivate
            picture
            cellphone
          }
        }
      }
      Description {
        text
        embeds {
          id
          configurations
          typeId
        }
        download
      }
      Rubric {
        download
        description
        rows {
          criterials {
            type
            name
            value
            description
          }
        }
      }
      Delivery {
        Recomendations {
          text
          embeds {
            id
            configurations
            typeId
          }
        }
        Alert {
          text
          link
          title
          description
        }
        Document {
          id
          send
          href
          comment
          date
          isDraft
          WorkTeamFile {
            id
            file
          }
          Sender {
            id
            profileId
            Person {
              id
              fullName
              picture
              email
            }
          }
        }
      }
      Evaluation {
        revisionDate
        comment
        fileRevision
        fileName
        Revisor {
          profileId
          Person {
            fullName
            picture
          }
        }
        RubricResult {
          criterials {
            type
            name
            value
            description
          }
        }
        points {
          total
          obtained
        }
      }
    }
  }
`

export type WorkDetailPayload = Api.WorkDetailPayload

export type WorkDetailInput = Api.ResourceInput

interface WorkDetailPayloadApollo {
  WorkDetail: WorkDetailPayload
}

interface WorkDetailInputApollo {
  ResourceInput: WorkDetailInput
}

export const useQueryWorkDetail = (
  options?: QueryHookOptions<WorkDetailPayloadApollo, WorkDetailInputApollo>
) => {
  return useQuery<WorkDetailPayloadApollo, WorkDetailInputApollo>(WorkDetail, options)
}
