export const formatUnitTitle = (title: string, isCursive?: boolean) => {
  let formattedTitle = title
  if (isCursive) {
    formattedTitle = title.replace(/"\s*([^"]+?)\s*"/g, '&nbsp;<em>$1</em>&nbsp;')
  } else {
    formattedTitle = title.replace(/"\s*([^"]+?)\s*"/g, '$1')
  }

  formattedTitle = formattedTitle
    .replace(/<\/?p>/gi, '') // Limpia etiquetas <p>
    .replace(/&nbsp;\s+/g, '&nbsp;') // Limpia espacios extra
    .replace(/\s+&nbsp;/g, '&nbsp;')
    .trim()

  return formattedTitle
}
