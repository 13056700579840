import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GetEvaluationRepetition = gql`
  ${STATUS_FIELDS}
  query getEvaluationRepetition($EvaluationCheckRepetitionInput: EvaluationCheckRepetitionInput!) {
    getEvaluationRepetition(input: $EvaluationCheckRepetitionInput) {
      status {
        ...StatusFields
      }
      icon
      message
      button {
        name
        href
      }
    }
  }
`

export type GetEvaluationRepetitionPayload = Api.EvaluationCheckRepetitionPayload

export type GetEvaluationRepetitionInput = Api.EvaluationCheckRepetitionInput

interface GetEvaluationRepetitionPayloadApollo {
  getEvaluationRepetition: GetEvaluationRepetitionPayload
}

interface GetEvaluationRepetitionInputApollo {
  EvaluationCheckRepetitionInput: GetEvaluationRepetitionInput
}

export const useLazyGetEvaluationRepetition = (
  options?: LazyQueryHookOptions<
    GetEvaluationRepetitionPayloadApollo,
    GetEvaluationRepetitionInputApollo
  >
) => {
  return useLazyQuery<GetEvaluationRepetitionPayloadApollo, GetEvaluationRepetitionInputApollo>(
    GetEvaluationRepetition,
    options
  )
}
