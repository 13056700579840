import { UnitBlockItemLesson } from '@eclass/api'

/** Devuelte los datos que debe llevar la clase con respecto a su id, como texto, icono y color a mostrar, como tambien el tipo que es
 */
export type TBlockAttendance = UnitBlockItemLesson['type']
type MeetingStatus = 'ATTENDED' | 'NOT_ATTEND' | 'JUSTIFY'
interface ValuesMeeting {
  [key: number]: MeetingStatus
}

export const setAttendance = () => {
  const valuesMeeting: ValuesMeeting = {
    1: 'ATTENDED',
    2: 'NOT_ATTEND',
    14: 'JUSTIFY',
  }

  const valuesMeetingRemote: ValuesMeeting = {
    11: 'ATTENDED',
    12: 'NOT_ATTEND',
    14: 'JUSTIFY',
  }

  /* Determina qué mapeo de valores usar basado en el tipo y si es una lessons */
  const handleType = (type: TBlockAttendance = 'MEETING', isLesson: boolean) => {
    if (type === 'MEETING_REMOTE' && !isLesson) {
      return valuesMeetingRemote
    }
    return valuesMeeting
  }

  /**
   * Retorna detalles de asistencia como estado, icono y texto
   */
  const attendance = ({
    id,
    isPerformance,
    type,
    isLesson = false,
  }: {
    id: number
    isPerformance?: boolean
    type?: TBlockAttendance
    isLesson?: boolean
  }):
    | {
        unidentified: boolean
      }
    | {
        status: string
        icon: string
        text: string
      } => {
    const status = {
      ATTENDED: {
        // asistio
        status: 'success',
        icon: 'attend',
        text: isPerformance ? 'CourseAttendedOK' : 'ContentAttended',
      },
      NOT_ATTEND: {
        // asistio
        status: 'error',
        icon: 'absent',
        text: isPerformance ? 'CourseNoAttend' : 'ContentDidntGo',
      },
      JUSTIFY: {
        // justificada
        status: 'info',
        icon: 'pendingInfo',
        text: isPerformance ? 'CourseJustifyAttend' : 'ContentJustifyAttend',
      },
    }

    const types: ValuesMeeting = handleType(type, isLesson)

    if (!status[types[id]]) {
      return {
        unidentified: true,
      }
    }

    return { ...status[types[id]] }
  }

  const attendanceType = ({
    id = 0,
    type,
    isLesson = false,
  }: {
    id: number
    type?: TBlockAttendance
    isLesson?: boolean
  }) => {
    const types: ValuesMeeting = handleType(type, isLesson)

    return {
      attended: types[id] === 'ATTENDED',
      notAttend: types[id] === 'NOT_ATTEND',
      justify: types[id] === 'JUSTIFY',
    }
  }

  return {
    attendance,
    attendanceType,
  }
}
