import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

/**
 * Obtiene la url para la cev
 */
export const LOGIN_CAD = gql`
  ${STATUS_FIELDS}
  mutation LoginCAD($input: LoginCADInput!) {
    loginCAD(input: $input) {
      status {
        ...StatusFields
      }
      url
    }
  }
`
interface LoginCADPayloadApollo {
  loginCAD: Api.LoginCADPayload
}

interface LoginCADInputApollo {
  input: Api.LoginCADInput
}

export const useMutationtLoginCAD = (
  options?: MutationHookOptions<LoginCADPayloadApollo, LoginCADInputApollo>
) => {
  return useMutation<LoginCADPayloadApollo, LoginCADInputApollo>(LOGIN_CAD, options)
}
