import { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { Icons, vars } from '@eclass/ui-kit'
import { formatUnitTitle } from 'app/Services/formatUnitTitle'

export type TLinkBreadcrumb = {
  name: string
  /** Si necesito hacer algo al hacer click en el item */
  onClick?: () => void
  /** si necesito redireccionarlo a otra url */
  href?: {
    url: string
    targetBlank: boolean
  }
  /** Redicreccionar por medio del router del proyecto */
  router?: {
    /** Componete utilizado para agregar los linkz */
    LinkComponent: any
    path: string
    state?: string
  }
}

interface IBreadcrumb {
  links: TLinkBreadcrumb[]
}

export const Breadcrumb = ({ links }: IBreadcrumb) => {
  if (!links || links?.length === 0) return <></>

  return (
    <nav>
      <Box as="ol" listStyleType="none" m={0} p={0} display="flex" gap="24px">
        {links.map((link) => (
          <Item link={link} key={link.name} />
        ))}
      </Box>
    </nav>
  )
}

const Item = ({ link: { onClick, href, router, name } }: { link: TLinkBreadcrumb }) => {
  if (router) {
    return (
      <ItemLayout>
        <router.LinkComponent to={{ pathname: router.path, state: router.state }}>
          {formatUnitTitle(name)}
        </router.LinkComponent>
      </ItemLayout>
    )
  } else if (onClick) {
    return <ItemLayout onClick={onClick}>{name}</ItemLayout>
  } else if (href) {
    return (
      <ItemLayout>
        <a href={href.url} target={href.targetBlank ? '_blank' : '_self'} rel="noreferrer">
          <Icons.GoBack />
          {name}
        </a>
      </ItemLayout>
    )
  }

  return <ItemLayout>{name}</ItemLayout>
}

const ItemLayout: FC<{ onClick?: () => void }> = ({ children, onClick }) => {
  return (
    <Box
      alignItems="center"
      as="li"
      cursor="pointer"
      display="flex"
      gap="12px"
      mb="0"
      whiteSpace="nowrap"
      sx={{
        '&, a, >button': {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: 'normal',
          textDecorationLine: 'underline',
        },
        '&, a, svg, >button': {
          color: vars('colors-neutral-gray'),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        svg: {
          color: vars('colors-main-blueGrey'),
        },
        'a, >button': {
          p: 0,
        },
        '>button': {
          p: 0,
        },
        ':hover': {
          '&, a, svg, >button': {
            color: vars('colors-main-deepSkyBlue'),
          },
        },
      }}
      onClick={onClick}
    >
      <Icons.GoBack />
      {children}
    </Box>
  )
}
